/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.error-text {
    text-decoration: underline #a30000;
}

.section-header {
    margin-top: 55px;
    margin-bottom: 8px;
}

.section-header:first-child {
    margin-top: 68px;
}

.section-header-icon {
    height: 16px;
    margin-right: 10px;
}

.section-header-title {
    font-size: 20px;
    font-weight: 300;
    text-align: left;
    color: #492392;
}

.forward-button {
    background-color: #9ad4d6;
    margin-top: 100px;
}

.back-button {
    background-color: #492392;
    margin-top: 10px;
}

.buttons-row {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

.pointer {
    cursor: pointer;
}

.add-button {
    background-color: #9ad4d6;
    margin-top: 19px;
    max-width: 140px;
}

.remove-button {
    background-color: #492392;
    margin-top: 19px;
    max-width: 140px;
}

.btn {
    border: none;
    text-align: center;
    border-radius: 10px;
    height: 45px;
    width: 247px;
    align-self: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: normal;
    color: #ffffff;
    -webkit-appearance: none;
}

form {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    margin-left: 20px;
    margin-right: 20px;
}

.form-input {
    height: 42px;
    border-radius: 5px;
    border: solid 1px rgba(57, 61, 63, 0.15);
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    color: #492392;
    padding-left: 15px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.form-input-tel-prefix {
    width: 55px;
    border-width: 0;
    padding: 0;
    font-size: 15px;
    font-weight: 300;
    text-align: left;
    color: #babbbc;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.form-input-tel {
    width: 70%;
    height: 42px;
    border-width: 0;
    font-size: 15px;
    font-weight: 300;
    text-align: left;
    color: #492392;
    padding-left: 5px;
    padding-top: 0;
    padding-bottom: 0;
    border-left: solid 1px #393d3f;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.date-input {
    display: inline-flex;
    width: 297px;
}

.select-input {
    padding-left: 10px;
    height: 48.2px;
    font-weight: 300;
}

select {
    background: url("../../asset/Polygon 2.svg") 98% center no-repeat;
    border:none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -ms-appearance: none; /* get rid of default appearance for IE8, 9 and 10*/
}

.form-label {
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    color: #393D3F;
    margin-left: 16px;
    margin-top: 12px;
    margin-bottom: 2px;
}

input[type=checkbox] {
    visibility: hidden;
}

.checkbox-container .checkmark {
    cursor: pointer;
    width: 19px;
    height: 19px;
    border: solid 1px #bfbfbf;
    background-color: #ffffff;
    border-radius: 5px;
    display: inline-flex;
    justify-content: center;
    vertical-align: bottom;
    margin-right: 10px;
    margin-left: 15px;
}

.checkbox-container .checkmark:after {
    opacity: 0;
    content: '';
    width: 9px;
    height: 5px;
    background: transparent;
    border: 3px solid #333;
    border-top: none;
    border-right: none;
    margin-top: 2px;
    transform: rotate(-45deg);
}

/**
 * Create the hover event of the tick
 */
.checkbox-container span:hover::after {
    opacity: 0.5;
}

/**
 * Create the checkbox state for the tick
 */
.checkbox-container input[type=checkbox]:checked + .checkbox-flex .checkbox-wrap .checkmark:after {
    opacity: 1;
}

.readOnly {
    opacity: 0.5;
}

.checkbox-input {
    margin-left: -21px;
}

.checkbox-container {
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    color: #492392;
    margin-top: 12px;
    cursor: pointer;
    user-select: none;
}

.spacer {
    width: 375px;
    height: 1460px;
    background-color: #f8f8f8;
}

.field-required {
    border-color: #A30000 !important;
}

.files-list {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #393d3f;
}

.field-title {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: #492392;
    margin-left: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.checkbox-text {
    font-weight: normal;
    color: #393d3f;
}

@media screen and (min-width: 700px) {
    .date-input {
        width: 496px;
    }
    .add-button {
        margin-left: 130px;
    }
    .remove-button {
        margin-right: 130px;
    }
}

@media screen and (min-width: 1050px) {
    form {
        margin-left: 175px;
        margin-right: 175px;
    }

    .date-input {
        width: 533px;
    }

    .section-header:first-child {
        margin-top: 80px;
    }
}
