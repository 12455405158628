.creditor-text {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    line-height: 1.38;
    color: #393d3f;
    margin-left: 42px;
    margin-right: 42px;
}

.creditor-links {
    margin-top: 49px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.creditor-link {
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 300;
    color: #492392;
}

@media screen and (min-width: 1050px) {
    .creditor-text {
        margin-left: 100px;
        margin-right: 100px;
    }
}
