.cookies {
    z-index: 100;
    height: 202px;
    width: 100%;
    background-color: #303436;
    position: fixed;
    bottom: 0;
}

.cookies-text {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: #ffffff;
    margin-top: 45px;
    margin-left: 30px;
    margin-right: 30px;
}

.cookies-bottom {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    margin-top: 29px;
    margin-left: auto;
    margin-right: 30px;
}

.cookies-bottom img {
    cursor: pointer;
}

.cookies-button {
    height: 34px;
    background-color: #bfbfbf;
    font-size: 13px;
    font-weight: 600;
    color: #393d3f;
    width: 128px;
    margin-right: 30px;
}

@media screen and (min-width: 700px) {
    .cookies {
        height: 100px;
        display: flex;
    }

    .cookies-text {
        margin-top: 38px;
        margin-right: 0;
    }

    .cookies-bottom {
        margin-top: 33px;
        width: 30%;
        display: inline-flex;
        align-self: baseline;
        margin-right: 50px;
    }

    .cookies-button {
        margin-right: 20px;
    }
}
