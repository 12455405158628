.pricing-text {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    color: #393d3f;
    margin-left: 42px;
    margin-right: 42px;
    line-height: 1.25;
}

.pricing-text-bold {
    font-weight: normal;
}

.pricing-text-main {
    font-weight: normal;
    text-align: center;
    margin-bottom: 33px;
}

.price-section {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    font-weight: normal;
    text-align: left;
    margin-left: 42px;
    margin-right: 42px;
}

@media screen and (min-width: 700px) {
    .pricing-text-main {
        font-size: 20px;
    }
}

@media screen and (min-width: 1050px) {
    .price-section {
        margin-left: 100px;
        margin-right: 100px;
    }

    .pricing-text {
        margin-left: 100px;
        margin-right: 100px;
    }
}
