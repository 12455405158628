.success-subheader {
    font-size: 16px;
    font-weight: 300;
    color: #393d3f;
    margin-top: 50px;
}

.success-text {
    font-size: 15px;
    font-weight: 300;
    text-align: center;
    color: #393d3f;
    margin-top: 30px;
    margin-left: 49px;
    margin-right: 49px;
}

@media screen and (min-width: 1050px) {
    .success-text {
        margin-left: 120px;
        margin-right: 120px;
    }
}
