.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main-title {
  font-size: 28px;
  font-weight: 300;
  text-align: left;
  padding-top: 70px;
  margin-left: 43px;
}

.main-subtitle {
  font-size: 20px;
  font-weight: 300;
  text-align: left;
  margin-left: 43px;
  margin-top: 35px;
}

.main-extra {
  width: 218px;
  margin-left: auto;
  margin-right: 10px;
  display: flex;
}

.main-bullets {
  color: #393d3f;
  padding-left: 22px;
  margin-top: 15px;
}

.main-bullets-text {
  margin-left: 10px;
}

.main-bullets li {
  font-size: 20px;
  font-weight: 300;
  text-align: left;
  color: #393d3f;
  margin-left: 60px;
  margin-bottom: 10px;
}

.Logo-x {
  height: 30px;
  cursor: pointer;
  padding-left: 30px;
}

.horizontal-line {
  margin-top: 20px;
  margin-bottom: 20px;
}

.header-section {
  height: 624px;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-image: linear-gradient(to bottom, #e6e7e7, #e6e7e7, #ffffff 86%, #ffffff);
}

.header-container {
  width: 455px;
  display: inline-flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 699px){
  .main-subtitle {
    width: 313px;
  }
}

@media screen and (min-width: 700px) {
  .header-container {
    width: 650px;
  }

  .main-title {
    font-size: 40px;
    margin-left: 78px;
    padding-top: 107px;
  }

  .main-subtitle {
    font-size: 20px;
    margin-left: 78px;
  }

  .main-extra {
    width: 280px;
    margin-left: 280px;
    margin-top: -35px;
  }

  .main-bullets li {
    font-size: 20px;
    margin-left: 93px;
  }

  .Logo-x {
    height: 48px;
    padding-left: 10px;
  }
}

@media screen and (min-width: 1050px) {
  .header-container {
    width: 950px;
  }

  .main-title {
    font-size: 49px;
    margin-left: 175px;
    padding-top: 107px;
  }

  .main-subtitle {
    font-size: 25px;
    margin-left: 175px;
  }

  .main-bullets {
    margin-top: 29px;
  }

  .main-bullets li {
    font-size: 25px;
    margin-left: 206px;
    margin-top: 10px;
    margin-bottom: 19px;
  }

  .main-extra {
    margin-top: -80px;
    width: 250px;
    margin-left: 490px;
  }

  .Logo-x {
    height: 48px;
  }

  .bm-menu-wrap {
    width: 310px !important;
  }
}
