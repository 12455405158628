.terms-header {
    font-size: 22px;
    font-weight: 300;
    text-align: center;
    color: #492392;
    margin-top: 68px;
}

.terms-text {
    margin-top: 15px;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    color: #393d3f;
    margin-left: 42px;
    margin-right: 42px;
}

.terms-text-main {
    margin-top: 68px;
    font-weight: normal;
}

@media screen and (min-width: 700px) {
    .terms-header {
        font-size: 30px;
    }

    .terms-text {
        margin-left: 50px;
        margin-right: 50px;
    }
}

@media screen and (min-width: 1050px) {
    .terms-text {
        margin-left: 100px;
        margin-right: 100px;
    }
}
