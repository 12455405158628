.application-header-row {
    display: inline-flex;
    margin-top: 50px;
    justify-content: space-between;
}

.application-edit {
    font-size: 12px;
    font-weight: 300;
    text-align: left;
    color: #393d3f;
    cursor: pointer;
}

.application-header-text {
    margin-left: 6px;
}

.application-data-row {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    text-align: left;
    color: #393d3f;
    display: flex;
    justify-content: space-between;
}

.application-row-value {
    font-size: 16px;
    font-weight: 300;
    text-align: right;
    color: #393d3f;
}

.application-link {
    font-size: 12px;
    font-weight: 300;
    line-height: 1.25;
    text-align: left;
    color: #492392;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 4px;
    margin-left: 47px;
    margin-right: 47px;
}

.application-text {
    margin-top: 26px;
    font-weight: 300;
    line-height: 1.25;
    text-align: left;
    color: #393d3f;
}

.application-button {
    background-color: #99dbbe;
    margin-top: 100px;
    font-size: 16px;
    font-weight: 600;
    width: 340px;
}
