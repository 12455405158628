.checkout-header {
    font-size: 25px;
    font-weight: 300;
    color: #492392;
    margin-top: 68px;
}

.checkout-text {
    font-size: 16px;
    font-weight: 300;
    color: #393d3f;
    margin-top: 40px;
    margin-bottom: 32px;
}

.checkout-sum {
    font-size: 100px;
    font-weight: 600;
    color: #492392;
}

.checkout-tax {
    font-size: 20px;
}

.checkout-link {
    cursor: pointer;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 20px;
}

@media screen and (min-width: 700px) {
    .checkout-text {
        font-size: 20px;
        max-width: 514px;
        display: inline-flex;
    }

    .checkout-link {
        margin-left: 0;
        margin-right: 0;
    }
}

@media screen and (min-width: 1050px) {
    .checkout-links {
        margin-left: 170px;
        margin-right: 159px;
        margin-bottom: 100px;
    }

    .checkout-links img {
        margin-right: 11px;
    }
}
