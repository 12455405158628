html {
  height: 100%;
}

body {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #492392;
  margin: 0;
  background-color: #f8f8f8;
  height: 100%;
}

#root {
  height: 100%;
}

.page-header {
  height: 100px;
  background-color: #fefefe;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.menu-button {
  cursor: pointer;
  height:30px;
  padding-right: 30px;
}

.menu-border {
  width: 36px;
  height: 36px;
  border-radius: 5px;
  border: solid 2px #492392;
}

.menu-line {
  width: 30px;
  height: 2px;
  background-color: #492392;
  margin: 6px 0 0 3px;
}

.footer {
  min-height: 308px;
  -webkit-backdrop-filter: blur(9px);
  backdrop-filter: blur(9px);
  background-color: #393d3f;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #fefefe;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-content {
  display: inline-flex;
  flex-direction: column;
  padding-bottom: 66px;
}

.footer-block {
  display: inline-flex;
  flex-direction: column;
  margin-top: 69px;
}

.footer-block-link{
  margin-bottom: 9px;
  text-align: left;
  color: #fefefe;
  text-decoration: none;
}

.footer-block-header {
  color: #9ad4d6;
  margin-bottom: 15px;
  font-weight: 500;
}

.footer-contacts-header {
  font-weight: 500;
  margin-bottom: 34px;
}

.footer-icon {
  height: 16px;
  margin-right: 20px;
  align-self: center;
}

.footer-text {
  text-decoration: none;
  color: #fefefe;
}

.footer-contacts {
  display: inline-flex;
  flex-direction: column;
}

.footer-contact {
  display: inline-flex;
  align-items: baseline;
  margin-bottom: 11px;
}

.content {
  background-color: #ffffff;
  margin-bottom: 47px;
  text-align: center;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08);
  margin-left: 25px;
  margin-right: 25px;
  width: 400px;
  z-index: 50;
}

.gray-background {
  background-color: #f8f8f8;
}

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 90%;
  background-color: #fefefe;
}

.background-image {
  /*background-image: url("asset/Backround 1.1.png");*/
  background-repeat: no-repeat;
  background-position: center 139px;
  background-size: 140% 567px;
}

ul {
  list-style-image: url("asset/list-dot.svg");
}

.progress-bar {
  margin-top: 90px;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 36px !important;
  width: 36px !important;
  margin-top: 44px;
  margin-right: 230px;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  top: 0;
  width: 311px !important;
}

/* General sidebar styles */
.bm-menu {
  box-shadow: 0 3px 6px 0 #393d3f;
  background-image: linear-gradient(to bottom, #492392, #2e165b, #251249);
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  margin-top: 140px;
  height: 70vh !important;
}

/* Individual item */
.bm-item {
  font-size: 24px;
  font-weight: normal;
  margin-top: 15px;
  text-align: right;
  color: #fefefe;
  cursor: pointer;
  margin-right: 31px;
}

/* Styling of overlay */
.bm-overlay {
  z-index: 9999;
  position: fixed;
  left:0;
  top:0;
  background: rgba(57, 61, 63, 0.45) !important;
}

.secondary-item {
  font-size: 13px;
  font-weight: normal;
  text-align: right;
  color: #fefefe;
  margin-top: 15px;
}

hr {
  width: 100%;
  opacity: 0.2;
}

.no-wrap {
  white-space: nowrap;
}

.subpage-header {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-image: linear-gradient(to bottom, #e6e7e7, #ffffff 96%, #ffffff);
  height: 63px;
  padding-top: 73px
}

.subpage-bottom-button {
  margin-bottom: 100px;
}

.text-bold {
  font-weight: normal;
}

.text-underline {
  text-decoration: underline;
}

.font-small {
  font-size: 12px;
}

@media screen and (min-width: 700px) {

  .subpage-header {
    font-size: 30px;
    height: 127px;
  }

  .content {
    margin-left: 50px;
    margin-right: 50px;
    width: 600px;
  }

  .footer-content {
    width: 640px;
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-block {
    margin-top: 80px;
  }

  .footer-block-header {
    font-size: 19px;
  }

  .footer-block-link {
    font-size: 14px;
  }

  .footer-contacts-header {
    font-size: 20px;
  }

  .footer-contact {
    font-size: 16px;
  }

  .font-small {
    font-size: 14px;
  }

  .menu-button {
    height: 48px;
    padding-right: 0;
  }
}

@media screen and (min-width: 1050px) {
  .content {
    margin-left: 190px;
    margin-right: 190px;
    width: 900px;
  }

  .footer-content {
    width: 946px;
  }

  .footer-block {
    margin-top: 77px;
  }

  .footer-block-header {
    font-size: 20px;
  }

  .footer-block-link {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .footer-contacts-header {
    font-size: 20px;
  }

  .footer-contact {
    font-size: 16px;
  }
}
