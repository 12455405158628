.proceeding-text {
    margin-top: 15px;
    font-weight: 300;
    text-align: left;
    color: #393d3f;
    margin-left: 42px;
    margin-right: 42px;
}

.proceeding-separator {
    width: 315px;
}

.proceeding-text-main {
    margin-top: 0px;
    font-weight: 300;
    text-align: left;
    color: #492392;
    margin-bottom: 26px;
}

.proceeding-section {
    text-align: left;
    color: #393d3f;
    align-items: center;
    margin: 20px 42px;
}

.proceeding-section-description {
    font-weight: 300;
}

.proceeding-duration {
    font-weight: 500;
    text-align: right;
    color: #492392;
    margin-top: 20px;
}

@media screen and (min-width: 700px) {
    .proceeding-text {
        margin-left: 50px;
        margin-right: 50px;
    }

    .proceeding-separator {
        width: 500px;
    }

    .proceeding-section {
        margin-left: 50px;
        margin-right: 50px;
    }
}

@media screen and (min-width: 1050px) {
    .proceeding-text {
        margin-left: 100px;
        margin-right: 100px;
    }

    .proceeding-separator {
        width: 700px;
    }

    .proceeding-section {
        margin-left: 100px;
        margin-right: 100px;
    }
}
