.contacts-info {
    font-size: 16px;
    font-weight: 300;
    color: #393d3f;
    text-align: center;
    margin: 30px 42px 53px;
}

.contacts-phone {
    height: 101px;
    margin-top: 121px;
}

.contacts-text-wrap {
    display: flex;
    justify-content: space-between;
    margin: 16px 42px 6px;
}

.contacts-separator {
    margin-top: 47px;
    margin-bottom: 47px;
}

.contacts-text-header {
    color: #393d3f;
}

.contacts-text {
    font-weight: normal;
    text-align: left;
    color: #492392;
    text-decoration: underline;
}

.contacts-mail {
    height: 72px;
    margin-top: 91px;
}

@media screen and (min-width: 700px) {
    .contacts-info {
        font-size: 19px;
    }

    .contacts-text-header {
        font-size: 19px;
    }

    .contacts-text {
        font-size: 25px;
    }
}

@media screen and (min-width: 1050px) {
    .contacts-info {
        margin-left: 100px;
        margin-right: 100px;
    }

    .contacts-text-wrap {
        margin-left: 100px;
        margin-right: 100px;
    }
}

