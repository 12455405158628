.gdpr-text {
    margin-top: 15px;
    line-height: 1.25;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    color: #393d3f;
    margin-left: 42px;
    margin-right: 42px;
}

.gdpr-text-main {
    font-weight: normal;
}

@media screen and (min-width: 700px) {

    .gdpr-text {
        margin-left: 50px;
        margin-right: 50px;
    }
}

@media screen and (min-width: 1050px) {
    .gdpr-text {
        margin-left: 100px;
        margin-right: 100px;
    }
}
